import { Turbo } from '@hotwired/turbo-rails'
import { broadcastEvent } from '../dispatch-event/dispatch-event'
import type {
  OpenDetail,
  ResolveMethod
} from '../../../../components/confirm_modal_component/types'

let _modal: HTMLElement | null = null

function init(): void {
  if (_modal !== null) {
    destroy()
  }

  _modal = document.querySelector('.modal__confirm')

  Turbo.setConfirmMethod(async (message: string, element: HTMLElement) => {
    const modal = document.querySelector('.modal__confirm')
    const button = element.querySelector(`[data-turbo-confirm='${message}']`)
    const target = button ?? element

    if (modal !== null && target !== null) {
      const dataset = target instanceof HTMLElement ? target.dataset : {}

      broadcastEvent<OpenDetail>(_modal as HTMLElement, 'open', {
        title: dataset.title,
        message,
        challenge: dataset.challenge,
        manualSubmit: dataset.manualSubmit === 'true' || dataset.manualSubmit === '',
        target
      })
    }

    return await new Promise(resolve => {
      broadcastEvent<{ resolve: ResolveMethod }>(_modal as HTMLElement, 'resolve', { resolve })
    })
  })
}

function destroy(): void {
  if (_modal === null) {
    return
  }

  _modal = null
}

const Confirm = {
  init,
  destroy
}

export default Confirm
