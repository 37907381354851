import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['box']

  declare readonly boxTargets: HTMLInputElement[]

  handleChange(e: Event): void {
    const checked = (e.target as HTMLInputElement).checked

    this.boxTargets.forEach(box => {
      if (checked) {
        box.readOnly = false
      } else {
        box.readOnly = true
        box.value = ''
      }
    })
  }
}
