import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'output']
  static values = { showIf: String }

  declare readonly inputTarget: HTMLInputElement
  declare readonly outputTargets: [HTMLInputElement]

  declare showIfValue: string

  connect(): void {
    this.toggle()
  }

  toggle(): void {
    if (this.inputTargetValue() === this.showIfValue) {
      this.outputTargets.forEach(function (element) {
        element.hidden = false
        element.classList.remove('hidden')
      })
    } else {
      this.outputTargets.forEach(function (element) {
        element.hidden = true
        element.classList.add('hidden')
      })
    }
  }

  private inputTargetValue(): string {
    if (this.inputTarget.type === 'checkbox') {
      return (+this.inputTarget.checked).toString()
    } else {
      return this.inputTarget.value
    }
  }
}
